import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Dashboard from'./pages/Dashboard'
import Login from "./pages/Login"
import Signup from "./pages/Signup"
import Layout from "./misc/Layout";
import Settings from "./pages/Settings"
import Profile from "./pages/Profile"
import ContactUs from "./pages/ContactUs"
import React from 'react'

const App = () => {

  return (
   <div className = "App"> 
    <Router> 
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />}/>
              <Route path="/login" element={<Login />}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/settings" element={<Settings/>}/>
              <Route path="/profile" element={<Profile/>}/>
              <Route path="/contactus" element={<ContactUs/>}/>
            </Routes>
          </Layout>
    </Router>
    </div>
  );
}

export default App;
