
import React from 'react';
import './Footer.css';
const Footer = () => (
  <div className = "footer">
    <footer className="Footer-footer">
    Copyright © 2023 Inkform. All Rights Reserved.
    </footer>
  </div>
)
export default Footer;