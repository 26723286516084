import React, { useEffect, useState } from 'react';
import './Header.css'
import logo from '../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const logout = () =>{
    localStorage.removeItem("user")
    navigate("/login")
  }
  return (
  <div className = "header">
      <Link to ="/" className = "header-logo">
        <img src= {logo} alt = "Logo" />
      </Link>
      <div className = "header-profile" onClick={()=>{setOpen(!open)}}>
      </div>
      <div className = {`profile-dd-menu ${open? 'active' : 'inactive'}`}>
          <Link to = "/profile" className = "dd-profile">
            Account
          </Link>
          <br/>
          <Link to = "/settings" className = "dd-settings">
            Settings
          </Link>
          <br/>
          <Link to = "/contactus" className = "dd-contact-us">
            Contact Us
          </Link>
          <br/>
          <Link to = "/login" className = "dd-contact-us" onClick = {logout}>
            Sign Out
          </Link>
          <br/>
      </div>
  </div>
  )
}
export default Header;