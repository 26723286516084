import Header from './Header'
import Footer from './Footer'
import LoginSignUpHeader from './LoginSignUpHeader'
import './Layout.css'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect} from 'react'


const Layout =({children}) =>{
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
    if(localStorage.getItem('user') === null){
       navigate("/login")
    }
    },[]) 
    if(location.pathname === "/login" || location.pathname === "/signup")
        return (
        <>
        <LoginSignUpHeader/>
            <div className="mainControl">
                <main>{children}</main>
            </div>
        <Footer/>
        </>
        )
    else
    return (
        <>
        <Header/>
            <div className="mainControl">
                <main>{children}</main>
            </div>
        <Footer/>
        </>
    )
}

export default Layout;