import React from 'react'
import './LoginSignUpHeader.css'
import logo from '../images/logo.png'

const LoginSignUpHeader = () => (
  <div className = "header">
        <div className = "header-logo">
          <img src= {logo} alt = "Logo" />
       </div>
  </div>
)
export default LoginSignUpHeader;