import './Dashboard.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useRef} from 'react';
import EditorToolbar, { modules, formats } from "../misc/EditorToolbar";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios';
import Modal from 'react-modal';


const Dashboard = (props) => {
  const navigate = useNavigate();  
  const [customToolName, setCustomToolName] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTool, setCurrentTool] = useState("Dashboard")
  const [currentToolId,setCurrentToolId] = useState("")
  const [currentCustomComponentNames, setCurrentCustomComponentNames] = useState([])
  const openModal = () => {
    setIsModalOpen(true);
  };
  const getComponent = async (id) =>{
    try{
    const component = await axios.get(`${process.env.REACT_APP_BACKEND}/tools/component`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`,
        "id":id
      }
    });

    return component
    }
    catch(err){
      console.log(err)
    }
  }
  const getCurr = () =>{
    useOperations.map((tool, toolIndex) => {
      if (tool.name === currentTool) {
        if (!customFields[toolIndex]) {
          const initialCustomFields = tool.operations.map(() => [""]);
          customFields[toolIndex] = initialCustomFields;
          console.log(tool)
        }    
        let componentName = "";    
        tool.operations.map(async (operation) => { 
          if (operation.op !== 'Summary' && operation.op !== 'Story' && operation.op !== 'Snippet') {
            const component = await getComponent(operation.op);
            componentName += component.data.apiResponse[0].name + ",";
            setCurrentCustomComponentNames(componentName.split(","))
          } 
        })
      }
    })
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const customModalStyles = {
    content: {
      top: '50%',      // Adjust these values to customize the position
      left: '50%',     // of the modal on the screen.
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  };
  const pickTool = (itemObject) =>{
    setCurrentComponentNames(prevNames => [...prevNames, itemObject.item.name])
    handleAddComponent(itemObject.item._id)
  }
  const renderCustomItems = () => {
    return customComponentItems.map((item, index) => (
      <div key={index}>
        <h3>{item.name}</h3>
        <button onClick={() => {pickTool({item})}}>use</button>
      </div>
    ));
  };
  const [currentComponentNames, setCurrentComponentNames] = useState("")
  const [customComponentName, setCustomComponentName] = useState("")
  const [customComponentExample, setCustomComponentExample] = useState("")
  const [customComponentPrompt, setCustomComponentPrompt] = useState("")
  const [outputValue, setOutputValue] = useState("")
  const [highlighted, setHighlighted] = useState(false);
  const [highlightedText, setHighlightedText] = useState("");
  const quillRef = useRef();
  const [open, setOpen] = useState(false)
  const [cartItems, setCartItems] = useState([]);
  const [customComponentItems, setCustomComponentItems] = useState([]);
  const [stories, setStories] = useState([""]);
  const [snippets, setSnippets] = useState([""]);
  const [read, setRead] = useState([""])
  const [listen, setListen] = useState([""])
  const [watch, setWatch] = useState([""])
  const [download, setDownload] = useState([""])
  const [loading, setLoading] = useState(false);
  const [psuloading, setPsuloading] = useState(false);
  const [operations, setOperations] = useState([]);
  const [useOperations, setUseOperations] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  
  const handleRemoveComponent = (index) => {
    setOperations(prevOperations => {
      const updatedOperations = [...prevOperations];
      updatedOperations.splice(index, 1);
      return updatedOperations;
    });
  };  
  const handleAddCustomField = (toolIndex, operationIndex) => {
    const newCustomFields = [...customFields];
    newCustomFields[toolIndex][operationIndex].push("");
    setCustomFields(newCustomFields);
    console.log(customFields)
  };
  const handleDeleteCustomField = (toolIndex, operationIndex, fieldIndex) => {
    const newCustomFields = [...customFields];
    newCustomFields[toolIndex][operationIndex].splice(fieldIndex, 1);
    setCustomFields(newCustomFields);
  };
  const handleCustomFieldChange = (event, toolIndex, operationIndex, fieldIndex) => {
    console.log(customFields)
    const newCustomFields = [...customFields];
    newCustomFields[toolIndex][operationIndex][fieldIndex] = event.target.value;
    setCustomFields(newCustomFields);
    console.log(useOperations)
  };
  const shorten = async () =>{
    setPsuloading(true);
    console.log(highlightedText)
    axios.post(`${process.env.REACT_APP_BACKEND}/tools/psuedo/shorten`, {"text": highlightedText},{
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
    })
    .then(res =>{
      if (quillRef.current) {
        const quillInstance = quillRef.current.getEditor();

        const range = quillInstance.getSelection();
        console.log(res.data.response)

        if (range) {
          quillInstance.deleteText(range.index, range.length);
          quillInstance.insertText(range.index, res.data.response[0]);
        }  
      }    
    })
    .catch((err =>{
      console.log(err)
    }))
    .finally(() =>{
      setPsuloading(false);
    })
  }
  const paraphrase = async () =>{
    setPsuloading(true);
    axios.post(`${process.env.REACT_APP_BACKEND}/tools/psuedo/paraphrase`, {"text": highlightedText},{
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
    })
    .then(res =>{
      if (quillRef.current) {
        const quillInstance = quillRef.current.getEditor();

        const range = quillInstance.getSelection();

        if (range) {
          quillInstance.deleteText(range.index, range.length);
          quillInstance.insertText(range.index, res.data.response[0]);
        }  
      } 
    })
    .catch((err =>{
      console.log(err)
    }))
    .finally(() =>{
      setPsuloading(false);
    })
  }
  const handleAddComponent = (op) => {
    const newOperation = {"op":op};
    setOperations(prevOperations => [...prevOperations, newOperation]);
    console.log(operations)
    setIsModalOpen(false)
  };
  const createLinkDelta = (text, url) => {
    return {
      insert: text,
      attributes: {
        link: url,
      }
    };
  }
  const splitString = (str) => {
    const parts = [];
    let buffer = "";
    let braceCount = 0;
  
    for (let i = 0; i < str.length; i++) {
      if (str[i] === "{") {
        if (braceCount === 0 && buffer) {
          parts.push(buffer);
          buffer = "";
        }
        buffer += "{";
        braceCount++;
      } else if (str[i] === "}") {
        buffer += "}";
        braceCount--;
        if (braceCount === 0) {
          parts.push(buffer);
          buffer = "";
        }
      } else {
        buffer += str[i];
      }
    }
  
    if (buffer) {
      parts.push(buffer);
    }
  
    return parts;
  };  
  const handleAddStory = () => {
    setStories([...stories, ""]);
  };
  const handleAddSnippet = () => {
    setSnippets([...snippets, ""]);
  };
  const handleStoryChange = (event, index) => {
    const newStories = [...stories];
    newStories[index] = event.target.value;
    setStories(newStories);
  };
  const handleSnippetChange = (event, index) => {
    const newSnippets = [...snippets];
    newSnippets[index] = event.target.value;
    setSnippets(newSnippets);
  };
  const handleDeleteStory = (index) => {
    const newStories = [...stories];
    newStories.splice(index, 1);
    setStories(newStories);
  };
  
  const handleDeleteSnippet = (index) => {
    const newSnippets = [...snippets];
    newSnippets.splice(index, 1);
    setSnippets(newSnippets);
  };
  const createTool = async () =>{
  if(customToolName !== "Custom Tool" && customToolName !== "CRE Newsletter Writer" && customToolName !== "Dashboard" && customToolName !== "Create Component"){
  axios.post(`${process.env.REACT_APP_BACKEND}/tools/custom`, {
    "operations": operations, "name": customToolName},{
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
    }).then(res =>{
      console.log(res)
      window.location.reload();
    })
    .catch((err =>{
      console.log(err)
    }))
  }
  else{
    console.log("can't do that")
  }
  }
  const createCustomComponent = async () =>{
    if(customComponentName !== "" && customComponentExample !== "" && customComponentPrompt !== ""){
    axios.post(`${process.env.REACT_APP_BACKEND}/tools/custom/component`, {
      "name": customComponentName, "example": customComponentExample, "prompt": customComponentPrompt},{
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
        }
      }).then(res =>{
        console.log(res)
        window.location.reload();
      })
      .catch((err =>{
        console.log(err)
      }))
    }
    else{
      console.log("Can't do that")
    }
    }
  const executeTool = async () =>{
    setLoading(true) 
    setOutputValue({
      ops: [{ insert: 'Generating Content...\n', attributes: { header: 1 } }]
    })
    const input = {
      "stories": stories,
      "snippets": snippets,
      "read": "" + read,
      "watch": "" + watch,
      "listen": "" + listen,
      "download": "" + download
    }
    axios.post(`${process.env.REACT_APP_BACKEND}/tools/use`, {"id": currentToolId,
    "input": input},{
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
    })
    .then(response =>{
      let delta = "" 
      let str = ""
      let quillDelta = [];
      const sum = response.data.sum
      const sumcost = sum[sum.length-1]
      if(sum.length > 1){
      for(let i = 0; i < sum.length-1; i++){
        str += sum[i] + " "
      }
      str += "\n\n"
      }
      const rwl = response.data.rwl
      const rwlcost = rwl[rwl.length-1]
      if(rwl.length > 1){
        for(let i = 0; i < rwl.length-1; i++){
          if(!(rwl[i].startsWith("Cannot Open"))){
            const parts = rwl[i].split(':');
            let subHeader = ""
            if(parts[2].trim().startsWith("Read")){
              subHeader = "Read"
              const firstSpaceIndex = parts[2].trim().indexOf(' ')
              parts[2] = parts[2].trim().substring(firstSpaceIndex + 1);
              const linkUrl = parts[0].trim() + ":" + parts[1].trim();
              delta = createLinkDelta(subHeader, linkUrl)
              str += JSON.stringify(delta) + " " + parts.slice(2).join(":") + "\n\n"
            }
            if(parts[2].trim().startsWith("Watch")){
              subHeader = "Watch"
              const firstSpaceIndex = parts[2].trim().indexOf(' ')
              parts[2] = parts[2].trim().substring(firstSpaceIndex + 1);
              const linkUrl = parts[0].trim() + ":" + parts[1].trim();
              delta = createLinkDelta(subHeader, linkUrl)
              str += JSON.stringify(delta) + " " + parts.slice(2).join(":") + "\n\n"
            }
            if(parts[2].trim().startsWith("Listen")){
              subHeader = "Listen"
              const firstSpaceIndex = parts[2].trim().indexOf(' ')
              parts[2] = parts[2].trim().substring(firstSpaceIndex + 1);
              const linkUrl = parts[0].trim() + ":" + parts[1].trim();
              delta = createLinkDelta(subHeader, linkUrl)
              str += JSON.stringify(delta) + " " + parts.slice(2).join(":") + "\n\n"
            }
            if(parts[2].trim().startsWith("Download")){
              subHeader = "Download"
              const firstSpaceIndex = parts[2].trim().indexOf(' ')
              parts[2] = parts[2].trim().substring(firstSpaceIndex + 1);
              const linkUrl = parts[0].trim() + ":" + parts[1].trim();
              delta = createLinkDelta(subHeader, linkUrl)
              str += JSON.stringify(delta) + " " + parts.slice(2).join(":") + "\n\n"
            }
          }
          else{
            str += rwl[i] + "\n\n"
          }
        }
      }
      const strys = response.data.strys
      const strycost = strys[strys.length-1]
      if(strys.length > 1){
        for(let i = 0; i < strys.length-1; i++){
          str += `Featured Story ${i+1} \n\n`  + strys[i] + "\n\n"
        }
      }
      const snpts = response.data.snpts
      const snptcost = snpts[snpts.length-1]
      if(snpts.length > 1){
        str += "\n\nSnippets\n\n"
        for(let i = 0; i < snpts.length-1; i++){
          if(!(snpts[i].startsWith("Cannot Open"))){
            const parts = snpts[i].split(':');
            if(parts.length<3){
              str += snpts[i] + "\n\n"
            }
            else{
              const subHeader = parts[2].trim()
              const linkUrl = parts[0].trim() + ":" + parts[1].trim();
              delta = createLinkDelta(subHeader, linkUrl)
              str += JSON.stringify(delta) + ":" + parts.slice(3).join(":") + "\n\n"
            }
          }
          else{
            str += snpts[i] + "\n\n"
          }
        }
      }
      const parts = splitString(str)
      console.log(parts)
      for (let i = 0; i < parts.length; i++) {
        try {
          const delta = JSON.parse(parts[i]);
          quillDelta.push(delta);
        } catch (error) {
          quillDelta.push({ insert: parts[i] });
        }
      }
      // Output quillDelta in a React Quill editor
      setOutputValue(quillDelta)
      let cost = 0;
      cost += parseInt(sumcost.replace(/[^-0-9]+/g,""));
      cost += parseInt(rwlcost.replace(/[^-0-9]+/g,""));
      cost += parseInt(strycost.replace(/[^-0-9]+/g,""));
      cost += parseInt(snptcost.replace(/[^-0-9]+/g,""));
      console.log("Cost:" + cost)
    })
    .catch(err =>{
      console.log(err)
    })
    .finally(() =>{
      setLoading(false);
    })
  }
  //not working yet
  const executeCustomTool = async () =>{
    setLoading(true) 
    setOutputValue({
      ops: [{ insert: 'Generating Content...\n', attributes: { header: 1 } }]
    })
    let input = []
    for(let i = 0; i < useOperations.length; i++){
      if(useOperations[i].name === currentTool){
        for(let j = 0; j < useOperations[i].operations.length; j++){
          let op = useOperations[i].operations[j].op
          let elements = customFields[i][j]
          input.push({op, elements})
        }
      }
    }
    axios.post(`${process.env.REACT_APP_BACKEND}/tools/custom/use`, {"id": currentToolId,
    "operations": input},{
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
    })
    .then(response =>{
      console.log("this is the response" + response)
      let delta = "" 
      let str = ""
      let quillDelta = [];
      console.log(response.data.result)
      for(let i = 0; i< response.data.result.length; i++){
        console.log(response.data.result[i])
        let sum = response.data.result[i][0].sum
        let stry = response.data.result[i][0].story
        let snppt = response.data.result[i][0].snippets
        let cus = response.data.result[i][0].results
        console.log(response)
        console.log(stry)
        console.log(snppt)
        console.log(cus)
        if(cus !== undefined){
          if(cus.length > 1){
            for(let j = 0; j < cus.length-1; j++){
              str += cus[j] + "\n\n"
            }
          }
        }
        if(sum !== undefined){
          if(sum.length > 1){
            for(let j = 0; j < sum.length-1; j++){
              str += sum[j] + "\n\n"
            }
          }
        }
        if(stry !== undefined){
          if(stry.length > 1){
            for(let j = 0; j < stry.length-1; j++){
              str += `Featured Story ${j+1} \n\n`  + stry[j] + "\n\n"
            }
          }
        }
        if(snppt !== undefined){
          if(snppt[0].length > 1){
            str += "\n\nSnippets\n\n"
            for(let j = 0; j < snppt.length-1; j++){
              if(!(snppt[j].startsWith("Cannot Open"))){
                const parts = snppt[j].split(':');
                if(parts != null){
                  console.log(parts)
                  if(parts[2] !== null ){
                    const subHeader = parts[2].trim()
                    const linkUrl = parts[0].trim() + ":" + parts[1].trim();
                    delta = createLinkDelta(subHeader, linkUrl)
                    str += JSON.stringify(delta) + ":" + parts.slice(3).join(":") + "\n\n"
                  }
                }
              }
              else{
                str += snppt[j] + "\n\n"
              }
            }
          }
        }
      }
      const parts = splitString(str)
      console.log(parts)
      for (let i = 0; i < parts.length; i++) {
        try {
          const delta = JSON.parse(parts[i]);
          quillDelta.push(delta);
        } catch (error) {
          quillDelta.push({ insert: parts[i] });
        }
      }
      // Output quillDelta in a React Quill editor
      setOutputValue(quillDelta)
    })
    .catch(err =>{
      console.log(err)
    })
    .finally(() =>{
      setLoading(false);
    })
  }

  const fetchTools = async () => {
    try {
      const normalToolsResponse = axios.get(`${process.env.REACT_APP_BACKEND}/tools`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
        }
      });
  
      const customToolsResponse = axios.get(`${process.env.REACT_APP_BACKEND}/tools/custom`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
        }
      });

      const customComponentResponse = axios.get(`${process.env.REACT_APP_BACKEND}/tools/custom/component`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
      }
      });
      const [normalTools, customTools, customComponents] = await Promise.all([normalToolsResponse, customToolsResponse,customComponentResponse]);
      const customComponentsData = customComponents.data;
      const normalToolsData = normalTools.data;
      const customToolsData = customTools.data;
  
      const tools = normalToolsData.length !== 0 ? [...normalToolsData] : ["No Tools Available"];
      const components = customComponentsData.length !== 0 ? [...customComponentsData] : ["No Components Available"];
  
      if (customToolsData.length !== 0) {
        for (let i = 0; i < customToolsData.length; i++) {
          tools.push(customToolsData[i]);
        }
        const ops = customToolsData.map(data => ({ name: data.name, operations: data.operations }));
        setUseOperations(ops);
      }
      setCartItems(tools);
      setCustomComponentItems(components)
      console.log(customComponentItems)
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  const DropdownItem = (props) =>{
    const isLast = cartItems.findIndex(item => item.key === props.key) === cartItems.length - 1;
    // this is terrible
    // will have to change this when we have more tools.
    const isCRE = props.text === "CRE Newsletter Writer" || props.text === "Custom Tool" || props.text === "Create Component";
    const className = `dropdownitem${isLast ? 'last' : ''}`;
    return(
      <li className = {className} onClick={()=>{
        setStories([""])
        setSnippets([""])
        setOpen(!open) 
        if(props.text !== "No Tools Available"){
          setCurrentTool(props.text)
          setCurrentToolId(props._id)
        }
        }}>
        <div className="dd-flexbox">
        {props.text}
        <button className = "dd-button" onClick={() => deleteCustomTool(props._id)} disabled = {isCRE} hidden ={isCRE}>x</button>
        </div>

      </li>
    )
  }
  const deleteCustomTool = async (id) => {
    window.location.reload();
    console.log(id)
    console.log(`Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`)
    axios.delete(`${process.env.REACT_APP_BACKEND}/tools/custom`, {data: {"id": id},
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("user")).data.token}`
        }
      }).then(res =>{
        console.log(res)
      })
      .catch((err =>{
        console.log(err)
      }))
  }
  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      localStorage.removeItem("user");
      navigate("/login");
    } else {
      fetchTools();
      if(useOperations !== []){
        getCurr();
      }
    }

    // Check if quillRef is set before accessing the editor
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor();
      const handleSelectionChange = (range) => {
        if (range) {
          const selectedText = quillInstance.getText(range.index, range.length);
          setHighlightedText(selectedText);
          setHighlighted(true);
        } else {
          setHighlightedText("");
          setHighlighted(false);
        }
      };
      quillInstance.on('selection-change', handleSelectionChange);
      return () => {
        quillInstance.off('selection-change', handleSelectionChange);
      };
    }
  }, [currentTool]);
  if(currentTool === "CRE Newsletter Writer"){
    return(
      <div>
          <h1 className = "dashboard-header">
            {currentTool} 
          </h1>
          <div className = "menu-container">
            <div className ="menu">
              <div className = "menu-trigger" onClick={()=>{setOpen(!open)}}>
                <h4>Tools</h4>
              </div>
            </div>
            <div className ="preview">
              <div className ="previewTools">
                <h4>Preview</h4>
                <div className = "sumShortBlock">
                  <button disabled={!highlighted || psuloading} onClick={() =>{paraphrase()}}>paraphrase</button>
                  <button disabled={!highlighted || psuloading} onClick={() => {shorten()}}>shorten</button>
                </div>
              </div>
            </div>
          </div>
          <div className = {`dd-menu ${open? 'active' : 'inactive'}`}>
            {cartItems.map(val => (
              <DropdownItem key={val._id} text={val.name}  _id = {val._id}/>
            ))}
          </div>
          <div className = "editor-control">
            <div className = "editor-input">
              <div>
                <h2>Stories</h2>
                {stories.map((story, index) => (
                  <div key={index} className="field-container">
                    <input
                      type="text"
                      value={story}
                      onChange={(event) => handleStoryChange(event, index)}
                    />
                    <button onClick={() => handleDeleteStory(index)}>-</button>
                    <button onClick={handleAddStory}>+</button>
                  </div>                 
                ))}

                <h2>Snippets</h2>
                {snippets.map((snippet, index) => (
                  <div key={index} className="field-container">
                    <input
                      type="text"
                      value={snippet}
                      onChange={(event) => handleSnippetChange(event, index)}
                    />
                    <button onClick={() => handleDeleteSnippet(index)}>-</button>
                    <button onClick={handleAddSnippet}>+</button>
                  </div>
                ))}
                <h2>Read</h2>
                  <input
                    type="text"
                    onChange={(e)=>setRead(e.target.value)}
                  />
                <h2>Watch</h2>
                  <input
                    type="text"
                    onChange={(e)=>setWatch(e.target.value)}
                  />
                <h2>Listen</h2>
                  <input
                    type="text"
                    onChange={(e)=>setListen(e.target.value)}
                  />
                <h2>Download</h2>
                <input
                  type="text"
                  onChange={(e)=>setDownload(e.target.value)}
                />
              </div>
            </div>
            <div className ="editor-output">
              <EditorToolbar />
              <ReactQuill ref={quillRef} theme="snow" modules={modules} formats = {formats} value={outputValue} onChange={setOutputValue}/>;    
            </div>
          </div>
          <div className = "v-line"/>
          <button disabled={loading} onClick={() =>{executeTool()}} className = "submit-button" type = "submit">Create Output</button>
      </div>
    )
  }
  if(currentTool.startsWith("Custom Tool")){
    let count = 0
    return(
      <div>
          <h1 className = "dashboard-header">
            {currentTool} 
          </h1>
          <div className = "menu-container">
            <div className ="menu">
              <div className = "menu-trigger" onClick={()=>{setOpen(!open)}}>
                <h4>Tools</h4>
              </div>
            </div>
            <div className ="preview">
              <h4>Preview</h4>
            </div>
          </div>
          <div className = {`dd-menu ${open? 'active' : 'inactive'}`}>
            {cartItems.map(val => (
              <DropdownItem key={val._id} text={val.name}  _id = {val._id}/>
            ))}
          </div>
          <div className = "editor-control">
            <div className = "editor-input">
              {/* let user add components */}
              <div class="box">
                <h2 class="ctheader2">Add Story Component</h2>
                <button onClick={() => handleAddComponent('Story')}>+</button>
              </div>

              <div class="box">
                <h2 class="ctheader2">Add Snippet Component</h2>
                <button onClick={() => handleAddComponent('Snippet')}>+</button>
              </div>

              <div class="box">
                <h2 class="ctheader2">Add Summary Component</h2>
                <button onClick={() => handleAddComponent('Summary')}>+</button>
              </div>

              <div class="box">
                <h2 class="ctheader2">Add Custom Component</h2>
                <button onClick={openModal}>+</button>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Custom Components"
                    style={customModalStyles}
                  >
                    <h2>Components</h2>
                    {renderCustomItems()}
                    <br/>
                    <button onClick={closeModal}>Close</button>
                  </Modal>
              </div>
            </div>
            <div className ="editor-output">
              <input
                type="text"
                placeholder="Tool Name"
                value={customToolName}
                onChange={(e) => setCustomToolName(e.target.value)}
              />
                 {/* Display added components */}
                  {operations.map((operation, index) => {
                    let componentToShow;

                    if (operation.op !== 'Summary' && operation.op !== 'Story' && operation.op !== 'Snippet') {
                      componentToShow = currentComponentNames[count];
                      count++; 
                    } else {
                      componentToShow = operation.op;
                    }
                    return(
                    <div key={index}>
                      <div className="component-block">
                        <div className="component-header">
                          <h2>{componentToShow}</h2>
                          <button onClick={() => handleRemoveComponent(index)}>Remove</button>
                        </div>
                      </div>
                        {index !== operations.length - 1 && (
                          <div className="arrow-down">&#8595;</div>
                        )}
                    </div>
                  )})}
            </div>
          </div>
          <div className = "v-line"/>
          <button onClick={() =>{createTool()}} className = "submit-button" type = "submit">Create Tool</button>
      </div>
    )
  }
  else if(currentTool === "Create Component"){
    return(
      <div>
          <h1 className = "dashboard-header">
            {currentTool} 
          </h1>
          <div className = "menu-container">
            <div className ="menu">
              <div className = "menu-trigger" onClick={()=>{setOpen(!open)}}>
                <h4>Tools</h4>
              </div>
            </div>
            <div className ="preview">
              <div className ="previewTools">
                <h4>Preview</h4>
                <div className = "sumShortBlock">
                  <button disabled={!highlighted || psuloading} onClick={() =>{paraphrase()}}>paraphrase</button>
                  <button disabled={!highlighted || psuloading} onClick={() => {shorten()}}>shorten</button>
                </div>
              </div>
            </div>
          </div>
          <div className = {`dd-menu ${open? 'active' : 'inactive'}`}>
            {cartItems.map(val => (
              <DropdownItem key={val._id} text={val.name}  _id = {val._id}/>
            ))}
          </div>
          <div className = "editor-control">
            <div className = "editor-input">
              <div>
                <h2>Name</h2>
                  <input
                    type="text"
                    onChange={(e)=>setCustomComponentName(e.target.value)}
                  />
                <h2>Example</h2>
                  <input
                    type="text"
                    onChange={(e)=>setCustomComponentExample(e.target.value)}
                  />
                <h2>Prompt</h2>
                  <input
                    type="text"
                    onChange={(e)=>setCustomComponentPrompt(e.target.value)}
                  />
              </div>
            </div>
            <div className ="editor-output">
              <EditorToolbar />
              <ReactQuill ref={quillRef} theme="snow" modules={modules} formats = {formats} value={outputValue} onChange={setOutputValue}/>;    
            </div>
          </div>
          <div className = "v-line"/>
          <button onClick={() =>{createCustomComponent()}} className = "submit-button" type = "submit">Create Component</button>
      </div>
    )
  }
  else if(currentTool !== "Dashboard"){
    // this should simply be how where we run the custom tools
    return(
      <div>
          <h1 className = "dashboard-header">
            {currentTool} 
          </h1>
          <div className = "menu-container">
            <div className ="menu">
              <div className = "menu-trigger" onClick={()=>{setOpen(!open)}}>
                <h4>Tools</h4>
              </div>
            </div>
            <div className ="preview">
              <div className ="previewTools">
                <h4>Preview</h4>
                <div className = "sumShortBlock">
                  <button disabled={!highlighted || psuloading} onClick={() =>{paraphrase()}}>paraphrase</button>
                  <button disabled={!highlighted || psuloading} onClick={() => {shorten()}}>shorten</button>
                </div>
              </div>
            </div>
          </div>
          <div className = {`dd-menu ${open? 'active' : 'inactive'}`}>
            {cartItems.map(val => (
              <DropdownItem key={val._id} text={val.name}  _id = {val._id}/>
            ))}
          </div>
          <div className = "editor-control">
          <div className="editor-input">
          <div>
          {useOperations.map((tool, toolIndex) => {
          if (tool.name === currentTool) {
            if (!customFields[toolIndex]) {
              const initialCustomFields = tool.operations.map(() => [""]);
              customFields[toolIndex] = initialCustomFields;
              console.log(tool)
            }      
            let count = 0  
            return tool.operations.map((operation, operationIndex) => {
              let componentToShow
              if(operation.op !== 'Summary' && operation.op !== 'Story' && operation.op !== 'Snippet'){
                componentToShow = currentCustomComponentNames[count]
                count ++
              } else{
                componentToShow = operation.op
              }
              return (
                <div key={operationIndex}>
                  <h2>{componentToShow}</h2>
                  {customFields[toolIndex]?.[operationIndex]?.map((field, fieldIndex) => (
                    <React.Fragment key={fieldIndex}>
                      <input
                        type="text"
                        value={field}
                        onChange={(event) => handleCustomFieldChange(event, toolIndex, operationIndex, fieldIndex)}
                      />
                      <button onClick={() => handleDeleteCustomField(toolIndex, operationIndex, fieldIndex)}>-</button>
                      <button onClick={() => handleAddCustomField(toolIndex, operationIndex)}>+</button>
                    </React.Fragment>
                  ))}
                </div>
              );
            });
          } else {
            return null;
          }
        })}
          </div>
          </div>
            <div className ="editor-output">
              <EditorToolbar />
              <ReactQuill ref={quillRef} theme="snow" modules={modules} formats = {formats} value={outputValue} onChange={setOutputValue}/>;    
            </div>
          </div>
          <div className = "v-line"/>
          <button disabled={loading} onClick={() =>{executeCustomTool()}} className = "submit-button" type = "submit">Create Output</button>
      </div>
    )
  }
  else{
    return(
      <div>
          <h1 className = "dashboard-header">
            {currentTool} 
          </h1>
          <div className = "menu-container">
            <div className ="menu">
              <div className = "menu-trigger" onClick={()=>{setOpen(!open)}}>
                <h4>Tools</h4>
              </div>
            </div>
            <div className ="preview">
              <div className ="previewTools">
                <h4>Preview</h4>
                <div className = "sumShortBlock">
                  <button disabled={!highlighted || psuloading} onClick={() =>{paraphrase()}}>paraphrase</button>
                  <button disabled={!highlighted || psuloading} onClick={() => {shorten()}}>shorten</button>
                </div>
              </div>
            </div>
          </div>
          <div className = {`dd-menu ${open? 'active' : 'inactive'}`}>
            {cartItems.map(val => (
              <DropdownItem key={val._id} text={val.name}  _id = {val._id}/>
            ))}
          </div>
          <div className = "editor-control">
          <div className="editor-input">
          </div>
            <div className ="editor-output">
              <EditorToolbar />
              <ReactQuill ref={quillRef} theme="snow" value={outputValue} onChange={setOutputValue} modules={modules} formats={formats}/>;    
            </div>
          </div>
          <div className = "v-line"/>
      </div>
    )
  }
}

export default Dashboard