import './Profile.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';

const Profile = (props) => {
  return(
        <div>
          <h1 className = "dashboard-header">
            Profile 
          </h1>
        </div>
  )
}

export default Profile