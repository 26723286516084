import './Login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
    console.log(token)
    if (token) {
      // Store the token in local storage
      localStorage.setItem('user', JSON.stringify({data:{ token }}));

      // Clear the query parameter
      window.history.replaceState({}, document.title, window.location.pathname);
      
      // Redirect the user to the desired page (change this accordingly)
      navigate("/"); // You might want to adjust the path to your desired route
    }
    if (localStorage.getItem('user')){
        navigate("/")
    }
  }, [])
  const getGoogleOauthURL = () =>{
    const rootUrl = "https://accounts.google.com/o/oauth2/v2/auth"
    const options = {
      redirect_uri: process.env.REACT_APP_GOOGLEREDIRECT,
      client_id: process.env.REACT_APP_CLIENT_ID,
      acces_type: "offline",
      response_type: 'code',
      prompt: 'consent',
      scope:[
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email"
      ].join(" ")

    }
    const qs = new URLSearchParams(options)

    return `${rootUrl}?${qs.toString()}`
  }
  async function login(){
    console.warn(username, password)
    let result = await axios.post(`${process.env.REACT_APP_BACKEND}/user/login`, {
      "username": username, "password": password
    })
    localStorage.setItem("user", JSON.stringify(result))
    navigate("/")
  }
  return(
    <div className = "border-box">
      <h1 className="login-header">
        Login 
      </h1>
        <div>
          <div className = "text-control">
            Login to access tools
          </div>
          <input type = "text"  placeholder = "Username"  id = "username"
          onChange = {(e)=>setUsername(e.target.value)}
          className = "form-control"/>
          <br/>
          <input type = "password"  placeholder = "Password" id = "password"
          onChange = {(e)=>setPassword(e.target.value)}
          className = "form-control"/>
          <br/>
          <button onClick={login} className = "loginButton" type = "submit">Continue</button>
          <br/>
          <br/>
          <a href={getGoogleOauthURL()}>
            Login with Google
          </a>
          <div className = "to-SignUpBlock">
            Don't have an account?    
            <Link to = "/signup"  className = "to-SignUp"> 
              Sign Up
            </Link>
          </div>
        </div>  
    </div>
  )
}

export default Login